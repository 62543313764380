import {gql} from 'apollo-boost';

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment variant on ProductVariant {
    id
    title
    image {
      transformedSrc
    }
    priceV2 {
      amount
      currencyCode
    }
    product {
      id
      title
      handle
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images(first: 1) {
        edges {
          node {
            altText
            transformedSrc
          }
        }
      }
      productType
      variants(first: 250) {
        edges {
          node {
            id
            title
            sku
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const BUNDLE_QUERY = gql`
  ${PRODUCT_VARIANT_FRAGMENT}
  query bundles($bundleIds: [ID!]!) {
    nodes(ids: $bundleIds) {
      ...variant
    }
  }
`;

export const BUNDLE_VARIANTS_QUERY = (handles) => gql`
  query productsByHandles {
  ${handles.map((handle, i) => `
    handle${i}: productByHandle(handle: "${handle}") {
      handle
      variants(first: 250) {
        edges {
          node {
            id
            sku
          }
        }
      }
    }
  `)}
  }
`;
