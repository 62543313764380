import {ltrim} from "./utilities/trim";

export default {
  PRODUCT: (handle) => ({
    href: '/product/[handle]',
    as: `/product/${handle}`,
  }),
  PAGE: (slug) => ({
    href: '/[slug]',
    as: `/${ltrim(slug, '/')}`,
  }),
  SHOP: () => ({
    href: { pathname: '/shop' },
  }),
  COLLECTION: (collection) => ({
    href: { pathname: '/shop/[collection]' },
    as: { pathname: `/shop/${collection}` },
  }),
};
