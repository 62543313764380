import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import get from "lodash/get";

const Meta = ({ seo }) => {
  const router = useRouter();

  const fullUrl = process.env.DUX_BASE_URL;
  const pageUrl = fullUrl + router.asPath;

  const defaultOgImage = fullUrl + "/dulux-ogimage.png";

  function includeNoIndex() {
    if (get(seo, "noIndex", true)) {
      return <meta key="robots" name="robots" content="noindex,nofollow" />;
    }
  }

  function includeProductTags() {
    const product = get(seo, "product");
    const selectedProduct = get(seo, "selectedProduct");

    if (product && selectedProduct) {
      return (
        <>
          <meta
            property="product:brand"
            content={get(
              product,
              "ctfFields.fields.shopifyProduct.vendor",
              "Dulux"
            )}
          />
          <meta property="product:availability" content="in stock" />
          <meta property="product:condition" content="new" />
          <meta
            property="product:price:amount"
            content={get(selectedProduct, "priceV2.amount")}
          />
          <meta
            property="product:price:currency"
            content={product.priceRange.minVariantPrice.currencyCode}
          />
          <meta
            property="product:retailer_item_id"
            content={get(selectedProduct, "sku")}
          />
          <meta
            property="product:item_group_id"
            content={get(selectedProduct, "title")}
          />
        </>
      );
    }
  }

  return (
    <Head>
      <title key="title">{get(seo, "title")} | Dulux Colour Shop</title>
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

      {includeNoIndex()}

      <meta property="fb:app_id" content="473464213503813" />
      <meta property="og:title" content={get(seo, "title")} />
      <meta property="description" content={get(seo, "description")} />
      <meta property="og:description" content={get(seo, "description")} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={get(seo, "image", defaultOgImage)} />
      <meta property="og:image:width" content={get(seo, "imageWidth", "791")} />
      <meta
        property="og:image:height"
        content={get(seo, "imageHeight", "494")}
      />
      <meta property="og:type" content="website" />
      {includeProductTags()}
    </Head>
  );
};

export default Meta;
