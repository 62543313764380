import React from "react";
import Link from "next/link";
import { useDebouncedCallback } from "use-debounce";

const Button = ({
  url,
  title,
  target,
  fullwidth,
  theme,
  children,
  action,
  disabled,
  debounced,
  debounceWait,
}) => {
  const [debouncedActionToTake] = useDebouncedCallback(() => {
    action();
  }, debounceWait);

  if (url) {
    return (
      // <Link >
      <a
        href={url}
        title={title}
        target={target}
        className={
          disabled
            ? "bg-white border-gray-400 text-gray-400 cursor-default"
            : getClass()
        }
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </a>
      // </Link>
    );
  }

  return (
    <button
      title={title}
      className={
        disabled
          ? "bg-white border-gray-400 text-gray-400 cursor-default border-2 text-center text-sm py-2 px-3 " +
            widthClass()
          : getClass()
      }
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );

  function handleClick(e) {
    if (action) {
      e.preventDefault();
      debounced ? debouncedActionToTake() : action();
    }
  }

  function getClass() {
    return (
      "text-center text-sm py-2 px-3 " +
      widthClass() +
      " " +
      colourSchemeClass()
    );
  }

  function widthClass() {
    return fullwidth
      ? "flex w-full items-center justify-center"
      : "inline-flex items-center justify-center";
  }

  function colourSchemeClass() {
    switch (theme) {
      case "dark":
        return "text-white bg-dulux border-2 border-dulux hover:border-dulux hover:bg-white hover:text-dulux transition-fade ";
      case "light":
        return "text-dulux bg-white border-2 border-dulux hover:bg-dulux hover:text-white transition-fade ";
      default:
        return "text-white bg-dulux border-2 border-dulux hover:border-dulux hover:bg-white hover:text-dulux transition-fade ";
    }
  }
};

Button.defaultProps = {
  url: null,
  title: "",
  target: "",
  fullwidth: true,
  theme: "dark",
  action: null,
  debounced: false,
  debounceWait: 500,
};

export default Button;
