import React from "react";
import dynamic from "next/dynamic";
import routes from "../../lib/routes";
import Link from "next/link";

const NewsletterFormWithNoSSR = dynamic(() => import("./NewsletterForm"), {
  ssr: false,
});

const Newsletter = () => {
  return (
    <div className="bg-dulux-med text-white mt-5">
      <div className="container px-3 py-8 md:py-16 mx-auto align-middle">
        <div className="flex flex-wrap">
          <div className="w-full sm:w-full md:w-full lg:w-1/2">
            <div className="mb-4 lg:mr-10">
              <h1 className="text-left lg:text-left text-2xl">
                Sign up for exclusive Dulux® paint tips, trends and techniques
                sent straight to your inbox.
              </h1>
              <p className="mt-3 hidden lg:block">
                You are signing up to receive the latest trends, inspiration and
                news in accordance with our{" "}
                <Link {...routes.PAGE("/privacy-collection-statement")}>
                  <a className="underline">Privacy Collection Statement</a>
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="w-full sm:w-full md:w-full lg:w-1/2 flex items-center">
            <div className="lg:ml-10 w-full">
              <NewsletterFormWithNoSSR />
            </div>
          </div>
        </div>
        <p className="mt-6 block lg:hidden">
          You are signing up to receive the latest trends, inspiration and news
          in accordance with our{" "}
          <Link {...routes.PAGE("/privacy-collection-statement")}>
            <a className="underline">Privacy Collection Statement</a>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
