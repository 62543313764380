import React from "react";
import PropTypes from "prop-types";
import * as FeatherIcons from "react-feather";
import get from "lodash/get";

const Icon = ({name, size, strokeWidth, color}) => {

  function getIconTag()
  {
    const IconObj = get(FeatherIcons, name);

    return (
      <IconObj size={size} strokeWidth={strokeWidth} color={color} />
    );
  }

  return (
    getIconTag()
  );
};

Icon.defaultProps = {
  size: 24,
  color: "#ffffff",
  strokeWidth: "2",
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
