import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Navbar from "../../components/ui/Navbar";
import Footer from "../../components/ui/Footer";
import Meta from "../Meta";
import { ToastContainer } from "react-toastify";
import { navData } from "../../data/globals";
import { totalItemCount } from "../../lib/selectors/checkout";
import get from "lodash/get";
import mixpanelActions from "../../lib/mixpanel";

const Layout = props => {
  const router = useRouter();
  const totalItems = totalItemCount();

  useEffect(() => {
    const trackAction = get(props.track, "action", "Page view");
    const trackDescription = {
      path: router.pathname,
      desc: get(props.track, "desc", ""),
    };

    mixpanelActions.track(trackAction, trackDescription);
  }, []);

  return (
    <>
      <Meta seo={props.seo} />
      <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        className="dulux-toast-container"
        toastClassName="dulux-toast"
      />
      <Navbar
        pages={navData}
        cartTotalItemCount={totalItems}
        backgroundColour={props.navBackgroundColour}
      />
      <main className="mt-32 md:mt-0">{props.children}</main>
      <Footer />
      <div id="modal" />
    </>
  );
};

Layout.propTypes = {
  track: PropTypes.object,
  seo: PropTypes.object,
  children: PropTypes.node,
  navBackgroundColour: PropTypes.string,
};

Layout.defaultProps = {
  track: {},
  seo: {},
  children: null,
  navBackgroundColour: "#001E45",
};

export default Layout;
