import get from "lodash/get";
import {useSelector} from "react-redux";
import {getAttributeValue} from "../utilities/shopify";
import {useQuery} from "@apollo/react-hooks";
import {shopify} from "../api";
import {BUNDLE_QUERY} from "../../queries/bundles";

export function checkoutData() {
  return useSelector(state => state.checkout.data);
}

export function checkoutLoading() {
  return useSelector(state => state.checkout.loading);
}

export function checkoutShippingLoading() {
  return useSelector(state => state.checkout.shippingLoading);
}

export function bundleIds() {
  const items = useSelector(state => state.checkout.data.lineItems.edges);

  return items.reduce((prev, current) => {
    const bundleId = getAttributeValue(current.node, '_bundleVariantId');

    // We have a bundle ID, and it hasn't been seen yet
    if (bundleId && prev.indexOf(bundleId) === -1) {
      return [...prev, bundleId];
    }

    return prev;
  }, []);
}

export function bundleItems() {
  const ids = bundleIds();

  return useQuery(BUNDLE_QUERY, {
    variables: {bundleIds: ids},
    client: shopify,
  });
}

export function lineItems() {
  const allItems = useSelector(state => state.checkout.data.lineItems.edges);
  const {data: bundles} = bundleItems();

  // Get the line items that aren't part of a bundle
  const singleItems = allItems.reduce((prev, current) => {
    if (!getAttributeValue(current.node, '_bundleVariantId')) {
      return [...prev, current.node];
    }

    return prev;
  }, []);

  const transformedBundles = get(bundles, 'nodes', []).map(variant => {
    const {product, ...rest} = variant;
    const firstCartVariant = allItems.reduce((prev, current) => {
      if (getAttributeValue(current.node, '_bundleVariantId') === variant.id) {
        return current.node;
      }

      return prev;
    }, null);

    return {
      ...product,
      variant: rest,
      quantity: firstCartVariant ? firstCartVariant.quantity: 1,
    }
  });

  return transformedBundles.concat(singleItems);
}

export function lineItemCount() {
  return useSelector(state => state.checkout.data.lineItems.edges.length);
}

export function totalItemCount() {
  return lineItems().reduce((prev, current) => {
    return prev + current.quantity;
  }, 0);
}

export function checkoutLink() {
  return useSelector(state => state.checkout.data.webUrl);
}
