export function hexToRGBArray(colour) {
  if (colour.charAt(0) === "#") {
    colour = colour.substr(1);
  }

  if (colour.length === 3) {
    colour =
      colour.charAt(0) +
      colour.charAt(0) +
      colour.charAt(1) +
      colour.charAt(1) +
      colour.charAt(2) +
      colour.charAt(2);
  }

  if (colour.length !== 6) {
    throw new Error(`Invalid hex colour: ${colour}`);
  }

  const rgb = [];

  for (let i = 0; i <= 2; i++) {
    rgb[i] = parseInt(colour.substr(i * 2, 2), 16);
  }

  return rgb;
}

export function luma(colour) {
  let rgb = typeof colour === "string" ? hexToRGBArray(colour) : colour;

  rgb = rgb.map(bitValue => {
    const value = bitValue / 255;

    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
}

export function contrast(colour1, colour2) {
  const lum1 = luma(colour1);
  const lum2 = luma(colour2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

export function contrastingColour(
  background,
  foregroundColours = ["#001E45", "#FFFFFF"]
) {
  return foregroundColours.reduce((first, second) => {
    const firstContrast = contrast(first, background);
    const secondContrast = contrast(second, background);

    return firstContrast > secondContrast ? first : second;
  });
}

export function productBackground(title) {
  switch (title) {
    case "Antique White U.S.A.® SW1H7":
      return "#EFEAE0";
    case "Ashville SG5A1":
      return "#c8c7c7";
    case "Baltica SG5B8":
      return "#383c42";
    case "Beige Royal S15B1":
      return "#CFC7B8";
    case "Beige Royal Quarter S15B1Q":
      return "#E4DFD4";
    case "Candle Bark S15B2":
      return "#c3bcaa";
    case "Casper White Quarter SW1H4":
      return "#EDECE6";
    case "Colorbond® Monument® C29":
      return "#404141";
    case "Colorbond® Shale Grey™ C4":
      return "#B2B4AF";
    case "Colorbond® Surfmist® C1":
      return "#D7D6CB";
    case "Colorbond® Woodland Grey® C23":
      return "#53514D";
    case "Cool Whites Bundle":
      return "#EDEDE5";
    case "Dandelion Yellow SB7A5":
      return "#fcd23b";
    case "Dark Grey Bundle":
      return "#3C3E3F";
    case "Dieskau SN4H1":
      return "#cbc9c5";
    case "Domino SG6G8":
      return "#3C3E3F";
    case "Double Bass SG6A9":
      return "#3C3538";
    case "Duck Egg Blue S28A3":
      return "#97b2b0";
    case "Endless Dusk SN4G3":
      return "#9B9EA0";
    case "Exterior Darks Bundle":
      return "#D7D6CB";
    case "Exterior Lights Bundle":
      return "#D7DAD8";
    case "Feather Soft SN3A1":
      return "#D6D2C8";
    case "Flooded Gum SN4H3":
      return "#A3A29F";
    case "Grey Pail SG6G1":
      return "#BABFC2";
    case "Highgate SP2B6":
      return "#D9DDDF";
    case "Hog Bristle® Half S14D1H":
      return "#E5DCCB";
    case "Hog Bristle® Quarter S14D1Q":
      return "#ECE5D7";
    case "Klavier SG6F9":
      return "#363436";
    case "Lexicon® SW1E3":
      return "#E7EAEA";
    case "Lexicon® Half SW1G2":
      return "#EDEFEE";
    case "Lexicon® Quarter SW1E1":
      return "#F1F2F1";
    case "Light Grey Bundle":
      return "#E7EAEA";
    case "Lilac Light S07C2":
      return "#D7C0BA";
    case "Malay Grey SN4H6":
      return "#6d6c68";
    case "Miller Mood SG6D1":
      return "#B6BEC5";
    case "Milton Moon SN4G2":
      return "#B1B4B3";
    case "Most Loved Whites Bundle":
      return "#EEECE5";
    case "Namadji™ SN4G8":
      return "#433F3B";
    case "Natural White™ SW1F4":
      return "#EEECE5";
    case "Oolong SG6D8":
      return "#383D43";
    case "Pre School S37B1":
      return "#B4C2CD";
    case "Raku SN4H7":
      return "#5A5858";
    case "Russet Tan S09F9":
      return "#81442f";
    case "Signature SG6D6":
      return "#545e67";
    case "Silkwort TSN4H2":
      return "#B6B5B3";
    case "Silver Tea Set SG6H1":
      return "#D5D5D0";
    case "Snow Season SN4B3":
      return "#CBCECC";
    case "Snowy Mountains Half SW1F3":
      return "#EDEDE5";
    case "Stepney SN4H4":
      return "#8F8D8A";
    case "Subtle Violet S03C3":
      return "#B29E9F";
    case "Teahouse SN4G6":
      return "#666966";
    case "Terrace White SN4F1":
      return "#D7DAD8";
    case "Ticking SG6G7":
      return "#494D53";
    case "Timeless Grey SN4G4":
      return "#888A89";
    case "Tranquil Retreat SN4G1":
      return "#C3C4C2";
    case "Vivid White™ SW1G1":
      return "#f7f8f4";
    case "Warm Whites Bundle":
      return "#EFEAE0";
    case "Wayward Grey SG6B8":
      return "#3f3c3e";
    case "Whisper White SW1C2":
      return "#EBE9E0";
    case "White Beach Quarter SW1C4":
      return "#E8E2D6";
    case "White Cloak Quarter SW1B1":
      return "#EEEAE0";
    case "White Duck Quarter S16B1Q":
      return "#E4E1D6";
    case "White Dune Half SW1B2":
      return "#EFEADC";
    case "White Exchange Half SW1B4":
      return "#E3E1D8";
    case "White On White SW1E2":
      return "#ECEFF0";
    case "White Polar Quarter SW1F2":
      return "#F2F0E6";
    case "Winter Trends Bundle":
      return "#D7C0BA";
    default:
      return "#001E45";
  }
}
