import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { navbarData } from "../../data/storybook/data";
import Hamburger from "./Hamburger";
import Button from "./Button";
import Icon from "../Icon";
import MobileMenu from "./MobileMenu";
import { contrastingColour } from "../../lib/utilities/colour";
import Link from "next/link";
import routes from "../../lib/routes";
import { ShoppingCart } from "react-feather";
import { trackEvent } from "../../lib/analytics";
import Modal from "react-modal";
import { motion } from "framer-motion";

const collectionLinks = [
  {
    link: routes.COLLECTION("whites"),
    label: "Whites",
  },
  {
    link: routes.COLLECTION("neutrals"),
    label: "Neutrals",
  },
  {
    link: routes.COLLECTION("greys"),
    label: "Greys",
  },
  {
    link: routes.COLLECTION("browns"),
    label: "Browns",
  },
  {
    link: routes.COLLECTION("pinks-and-purples"),
    label: "Pinks and Purples",
  },
  {
    link: routes.COLLECTION("blues"),
    label: "Blues",
  },
  {
    link: routes.COLLECTION("greens"),
    label: "Greens",
  },
  {
    link: routes.COLLECTION("yellows-and-oranges"),
    label: "Yellows and Oranges",
  },
  {
    link: routes.COLLECTION("reds"),
    label: "Reds",
  },
  {
    link: routes.COLLECTION("colorbond"),
    label: "Colorbond®",
  },
  {
    link: routes.COLLECTION("colour-stickers"),
    label: "Colour Stickers",
  },
  {
    link: routes.COLLECTION("colour-forecast-2022"),
    label: "Colour Forecast 2022",
  },
  {
    link: routes.COLLECTION("specifier-tools"),
    label: "Specifier Tools",
  },
];

const bundleLinks = [
  {
    link: routes.PRODUCT("cool-whites"),
    label: "Cool Whites",
  },
  {
    link: routes.PRODUCT("light-grey"),
    label: "Light Greys",
  },
  {
    link: routes.PRODUCT("warm-whites"),
    label: "Warm Whites",
  },
  {
    link: routes.PRODUCT("dark-grey"),
    label: "Dark Greys",
  },
  {
    link: routes.PRODUCT("most-loved-whites"),
    label: "Most Loved Whites",
  },
  {
    link: routes.PRODUCT("exterior-lights"),
    label: "Exterior Lights",
  },
  {
    link: routes.PRODUCT("exterior-darks"),
    label: "Exterior Darks",
  },
];

const Navbar = props => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function toggleActiveState() {
    mobileMenuActive ? setMobileMenuActive(false) : setMobileMenuActive(true);
  }

  const [showNavigation, setShowNavigation] = useState(false);

  useEffect(() => {
    if (showNavigation) {
      document.body.classList.add("overflow-hidden");
    } else if (!showNavigation) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showNavigation]);

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  function toggleNav() {
    setShowNavigation(!showNavigation);
    trackEvent("Shop link", "sample_shop", "click", "shop_link_click");
  }

  function closeNav() {
    setShowNavigation(false);
  }

  function cartOnClick() {
    trackEvent("Cart link", "sample_shop", "click", "cart_link_click");
  }

  return (
    <>
      <div
        className="fixed left-0 z-40 hidden md:block"
        style={{
          height: "100vh",
          width: "100vw",
          top: showNavigation ? "0vh" : "-100vh",
          transition: "all 1100ms cubic-bezier(0.33, 1, 0.68, 1)",
          color: contrastingColour(props.backgroundColour),
          backgroundColor: props.backgroundColour,
        }}
      >
        <div
          className="container px-3 mx-auto"
          style={{
            paddingTop: "17rem",
            transition: "all 800ms",
            opacity: showNavigation ? "100" : "0",
            transitionDelay: "800ms",
          }}
        >
          <div className="flex justify-between">
            <div className="w-6/12 pr-4">
              <div className="text-lg tracking-widest uppercase font-semibold">
                Colour
              </div>
              <ul className="flex flex-row flex-wrap font-light mt-4 text-lg">
                {collectionLinks.map(collection => (
                  <li key={collection.label} className="w-1/2 mb-2">
                    <Link {...collection.link}>
                      <a onClick={closeNav}>{collection.label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-6/12 pl-4">
              <div className="text-lg tracking-widest uppercase font-semibold">
                Bundles
              </div>
              <ul className="flex flex-row flex-wrap font-light mt-4 text-lg">
                {bundleLinks.map(bundle => (
                  <li key={bundle.label} className="w-1/2 mb-2">
                    <Link {...bundle.link}>
                      <a onClick={closeNav}>{bundle.label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="text-lg tracking-widest uppercase font-semibold mt-8">
            <Link href="/shop">
              <a onClick={closeNav} className="flex items-center">
                Shop All{" "}
                <svg
                  className="w-16 h-16 ml-1"
                  viewBox="0 0 76 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M64.686 39.0032L9.37856 39.0032V37.4832L64.686 37.4832V39.0032Z"
                    fill={contrastingColour(props.backgroundColour)}
                  />
                  <path
                    d="M59.8531 33.0599C60.0074 33.0599 60.1618 33.1074 60.2984 33.2054L65.1404 36.7145C65.6629 37.0915 65.9627 37.6408 65.9627 38.2226C65.9657 38.8015 65.6659 39.3537 65.1464 39.7337L60.2984 43.2814C59.957 43.5308 59.485 43.4566 59.2356 43.1152C58.9892 42.7767 59.0604 42.3017 59.3989 42.0524L64.2468 38.5047C64.3715 38.4156 64.4428 38.3117 64.4398 38.2227C64.4398 38.1336 64.3686 38.0327 64.2439 37.9436L59.4018 34.4345C59.0634 34.1881 58.9862 33.7131 59.2326 33.3747C59.384 33.1699 59.6156 33.06 59.8531 33.06L59.8531 33.0599Z"
                    fill={contrastingColour(props.backgroundColour)}
                  />
                </svg>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="fixed z-50 top-0 left-0 right-0 md:relative overflow-hidden md:overflow-visible"
        style={{
          color: contrastingColour(props.backgroundColour),
          backgroundColor: props.backgroundColour,
          transition: "all 250ms",
          WebkitTransition: "all 250ms",
          MozTransition: "all 250ms",
        }}
      >
        <div className="z-50 container mx-auto px-3 h-32 md:h-16 md:h-auto md:py-12 flex flex-col justify-center relative">
          <div
            className="w-full bottom-0 absolute left-0 px-3"
            style={{
              transition: "all 700ms",
              opacity: showNavigation ? "100" : "0",
              transitionDelay: "400ms",
            }}
          >
            <div
              className="w-full hidden md:block"
              style={{
                height: "1px",
                backgroundColor: contrastingColour(props.backgroundColour),
              }}
            ></div>
          </div>
          <div className="flex justify-between items-start md:items-end">
            <div>
              <div>
                <Link href="/">
                  <a>
                    <img
                      src={navbarData.logo.src}
                      alt={navbarData.logo.alt}
                      className="h-8 md:h-12"
                    />
                  </a>
                </Link>
              </div>
              <motion.div
                className="text-2xl md:text-3xl lg:text-5xl leading-none pt-4 md:pt-6"
                initial={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                animate={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                transition={{ duration: 0.6, ease: "easeOut" }}
              >
                <Link href="/">
                  <a>Dulux Colour Shop</a>
                </Link>
              </motion.div>
            </div>
            <ul className="uppercase flex lg:text-lg">
              <motion.div
                initial={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                animate={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
              >
                <li className="hidden md:flex px-2 lg:px-6">
                  <Link href="/">
                    <a className="tracking-widest hover:underline transition-all duration-150">
                      home
                    </a>
                  </Link>
                </li>
              </motion.div>
              <motion.div
                initial={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                animate={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                transition={{ duration: 0.6, delay: 0.4, ease: "easeOut" }}
              >
                <li className="hoverable px-6 hidden md:flex">
                  <a
                    className="tracking-widest cursor-pointer flex items-center"
                    onClick={toggleNav}
                  >
                    shop{" "}
                    <span
                      className="ml-1"
                      style={{
                        transform: showNavigation
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "all 700ms cubic-bezier(0.33, 1, 0.68, 1)",
                      }}
                    >
                      <Icon
                        name="ChevronDown"
                        color={contrastingColour(props.backgroundColour)}
                      />
                    </span>
                  </a>
                </li>
              </motion.div>
              <motion.div
                initial={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                animate={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                transition={{ duration: 0.6, delay: 0.6, ease: "easeOut" }}
              >
                <li className="hidden md:flex px-3 lg:px-6">
                  <Link href="/cart">
                    <a
                      className="tracking-widest hover:underline transition-all duration-150"
                      onClick={cartOnClick}
                    >
                      Cart ({props.cartTotalItemCount})
                    </a>
                  </Link>
                </li>
              </motion.div>
              <motion.div
                initial={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                animate={{
                  opacity: isPageLoaded ? 1 : 0,
                  y: isPageLoaded ? "0%" : "-30%",
                }}
                transition={{ duration: 0.6, delay: 1, ease: "easeOut" }}
              >
                <li className="hidden md:flex px-3 lg:px-6">
                  <a
                    className="tracking-widest hover:underline transition-all duration-150 cursor-pointer"
                    onClick={openModal}
                  >
                    Dulux Website
                  </a>
                </li>
              </motion.div>
            </ul>
            <div className="flex md:hidden">
              <Link href="/cart">
                <a className="pr-8 relative">
                  <ShoppingCart />
                  <span
                    style={{ transform: "translate(-50%, -50%)" }}
                    className="flex justify-center items-center absolute w-6 h-6 bg-dulux text-white rounded-full text-sm top-0 right-0"
                  >
                    {props.cartTotalItemCount}
                  </span>
                </a>
              </Link>
              <Hamburger
                mobileMenuActive={mobileMenuActive}
                toggleActiveState={toggleActiveState}
                colour={contrastingColour(props.backgroundColour)}
              />
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => setMobileMenuActive(false)}>
        <MobileMenu
          pages={props.pages}
          mobileMenuActive={mobileMenuActive}
          backgroundColour={props.backgroundColour}
          textColour={contrastingColour(props.backgroundColour)}
          borderColour={contrastingColour(props.backgroundColour)}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
          document.body.classList.add("overflow-hidden");
        }}
        onRequestClose={() => {
          document.body.classList.remove("overflow-hidden");
          closeModal();
        }}
        contentLabel="Example Modal"
      >
        <p>Just a reminder that you're now leaving the Dulux Colour Shop.</p>
        <p className="my-4">
          Couldn't find what you’re looking for? Shop our full range of colour
          samples on the{" "}
          <a
            href="https://www.dulux.com.au/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Dulux website
          </a>
          .
        </p>
        <Button url="https://www.dulux.com.au/" target="_blank">
          Proceed
        </Button>
        <i
          className="fas fa-times text-xl absolute cursor-pointer"
          onClick={() => {
            document.body.classList.remove("overflow-hidden");
            closeModal();
          }}
          style={{ top: "15px", right: "15px" }}
        ></i>
      </Modal>
    </>
  );
};

Navbar.propTypes = {
  pages: PropTypes.array,
  cartTotalItemCount: PropTypes.number,
  textColour: PropTypes.string,
  backgroundColour: PropTypes.string,
};

Navbar.defaultProps = {
  pages: [],
  cartTotalItemCount: 0,
  textColour: "#FFFFFF",
  backgroundColour: "#001E45",
};

export default Navbar;
