import duluxLogoBlue from "../../public/dulux-logo-blue.svg";
import duluxLogoWhite from "../../public/dulux-logo-white.svg";
import duluxLogo from "../../public/dulux-logo.png";
import carousel1 from "../../public/carousel/carousel-1.jpeg";
import carousel2 from "../../public/carousel/carousel-2.jpeg";
import carousel3 from "../../public/carousel/carousel-3.jpeg";
import carousel4 from "../../public/carousel/carousel-4.jpeg";
import carousel5 from "../../public/carousel/carousel-5.jpeg";

export const navbarData = {
  logo: {
    src: duluxLogo,
    alt: "Dulux Logo",
  },
};

import heroImage from "../../public/hero.png";

export const heroData = {
  image: {
    src: heroImage,
    alt: "Suburban House",
  },
  title: "Introducing Dulux Repair",
  description: `Repair helps us revive our relationship with nature and embrace a more holistic and 'circular'
  way of living. Our comfortable, carefree existence begins to fade into the past, as we adopt
  a wabi-sabi sensibility that appreciates the beauty in faults and flaws.`,
};

export const headerData = {
  title: "Introducing Dulux Repair",
  description: `Repair helps us revive our relationship with nature and embrace a more holistic and 'circular'
  way of living. Our comfortable, carefree existence begins to fade into the past, as we adopt
  a wabi-sabi sensibility that appreciates the beauty in faults and flaws.`,
};

export const productImage =
  "https://images.pexels.com/photos/1561020/pexels-photo-1561020.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";

export const heroCarouselData = [
  {
    image: {
      src: carousel1,
      alt: "carousel 1",
    },
  },
  {
    image: {
      src: carousel2,
      alt: "carousel 2",
    },
  },
  {
    image: {
      src: carousel3,
      alt: "carousel 3",
    },
  },
  {
    image: {
      src: carousel4,
      alt: "carousel 4",
    },
  },
  {
    image: {
      src: carousel5,
      alt: "carousel 5",
    },
  },
];

export const heroCarouselDataSingle = [
  {
    image: {
      src: carousel1,
      alt: "carousel 1",
    },
  },
];

export const galleryData = [
  {
    image: {
      src:
        "https://images.pexels.com/photos/3119180/pexels-photo-3119180.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      alt: "gallery data 1",
    },
  },
  {
    image: {
      src:
        "https://images.pexels.com/photos/2876511/pexels-photo-2876511.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      alt: "gallery data 2",
    },
  },
  {
    image: {
      src:
        "https://images.pexels.com/photos/2938207/pexels-photo-2938207.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      alt: "gallery data 3",
    },
  },
  {
    image: {
      src:
        "https://images.pexels.com/photos/3012603/pexels-photo-3012603.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      alt: "gallery data 4",
    },
  },
  {
    image: {
      src:
        "https://images.pexels.com/photos/2387819/pexels-photo-2387819.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      alt: "gallery data 5",
    },
  },
];

export const radioOptionData = [
  {
    name: "option",
    label: "Sample Pot",
    secondaryLabel: "$10",
    value: "samplepot",
  },
  {
    name: "option",
    label: "Colour Sticker",
    secondaryLabel: "$15",
    value: "coloursticker",
  },
  {
    name: "option",
    label: "Paper Swatch",
    secondaryLabel: "$20",
    value: "paperswatch",
  },
  {
    name: "option",
    label: "Option 4",
    value: "option4",
  },
];

export const radioProductData = [
  {
    name: "type",
    label: "Sample Pot",
    value: "sample_pot",
  },
  {
    name: "type",
    label: "Colour Swatch",
    value: "colour_swatch",
  },
];

export const accordionTabs = [
  {
    heading: "Item 1",
    content: "<p>My Content 1</p>",
    slug: "item-1",
  },
  {
    heading: "Item 2",
    content: "<p>My Content 2</p>",
    slug: "item-2",
  },
  {
    heading: "Item 3",
    content: "<p>My Content 3</p>",
    slug: "item-3",
  },
  {
    heading: "Item 4",
    content: "<p>My Content 4</p>",
    slug: "item-4",
  },
];

export const accordionTabData = {
  title: "Frequently Answered Questions",
  tabs: accordionTabs,
};

export const tabData = {
  tabs: [
    {
      heading: "Tab #1",
      content: `<h1>Tab #1 Content</h1><p>Donec sollicitudin gravida auctor. Donec faucibus vitae risus vitae pretium. Mauris vulputate purus tellus, a dignissim risus placerat eu. Vivamus bibendum ante felis, ac rutrum eros aliquet id. In placerat tristique neque, quis iaculis justo sagittis eget. Mauris vulputate sem odio, in aliquam neque eleifend et. Praesent sem purus, sagittis quis feugiat quis, gravida eget nibh. Ut scelerisque tellus nec quam pretium maximus.</p>`,
    },
    {
      heading: "Tab #2",
      content: `<h1>Tab #2 Content</h1><p>Donec sollicitudin gravida auctor. Donec faucibus vitae risus vitae pretium. Mauris vulputate purus tellus, a dignissim risus placerat eu. Vivamus bibendum ante felis, ac rutrum eros aliquet id. In placerat tristique neque, quis iaculis justo sagittis eget. Mauris vulputate sem odio, in aliquam neque eleifend et. Praesent sem purus, sagittis quis feugiat quis, gravida eget nibh. Ut scelerisque tellus nec quam pretium maximus.</p>`,
    },
    {
      heading: "Tab #3",
      content: `<h1>Tab #3 Content</h1><p>Donec sollicitudin gravida auctor. Donec faucibus vitae risus vitae pretium. Mauris vulputate purus tellus, a dignissim risus placerat eu. Vivamus bibendum ante felis, ac rutrum eros aliquet id. In placerat tristique neque, quis iaculis justo sagittis eget. Mauris vulputate sem odio, in aliquam neque eleifend et. Praesent sem purus, sagittis quis feugiat quis, gravida eget nibh. Ut scelerisque tellus nec quam pretium maximus.</p>`,
    },
    {
      heading: "Tab #4",
      content: `<h1>Tab #4 Content</h1><p>Donec sollicitudin gravida auctor. Donec faucibus vitae risus vitae pretium. Mauris vulputate purus tellus, a dignissim risus placerat eu. Vivamus bibendum ante felis, ac rutrum eros aliquet id. In placerat tristique neque, quis iaculis justo sagittis eget. Mauris vulputate sem odio, in aliquam neque eleifend et. Praesent sem purus, sagittis quis feugiat quis, gravida eget nibh. Ut scelerisque tellus nec quam pretium maximus.</p>`,
    },
  ],
};

const carouselSlides = [
  {
    title: "Slide #1",
    image: {
      title: "Slide #1 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #2",
    image: {
      title: "Slide #1 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #3",
    image: {
      title: "Slide #1 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #4",
    image: {
      title: "Slide #4 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #5",
    image: {
      title: "Slide #5 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #6",
    image: {
      title: "Slide #6 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #7",
    image: {
      title: "Slide #7 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
  {
    title: "Slide #8",
    image: {
      title: "Slide #8 Content",
      src: "https://via.placeholder.com/600x360",
    },
  },
];

export const carouselData = {
  title: "Carousel Title",
  slides: carouselSlides,
};

const features = [
  {
    title: "Feature #1",
    icon: {
      name: "Archive",
      size: "50",
    },
  },
  {
    title: "Feature #2",
    icon: {
      name: "Award",
      size: "50",
    },
  },
  {
    title: "Feature #3",
    icon: {
      name: "BookOpen",
      size: "50",
    },
  },
  {
    title: "Feature #4",
    icon: {
      name: "Box",
      size: "50",
    },
  },
  {
    title: "Feature #5",
    icon: {
      name: "Film",
      size: "50",
    },
  },
];

export const featureData = {
  title: "Features",
  features: features,
};

export const filterData = [
  {
    title: "Filter 1",
    open: true,
    items: [
      {
        label: "Condition 1",
        value: "condition1",
      },
      {
        label: "Condition 2",
        value: "condition2",
      },
      {
        label: "Condition 3",
        value: "condition3",
      },
    ],
  },
  {
    title: "Filter 2",
    open: true,
    items: [
      {
        label: "Condition 4",
        value: "condition4",
      },
      {
        label: "Condition 5",
        value: "condition5",
      },
      {
        label: "Condition 6",
        value: "condition6",
      },
    ],
  },
  {
    title: "Filter 3",
    items: [
      {
        label: "Condition 7",
        value: "condition7",
      },
      {
        label: "Condition 8",
        value: "condition8",
      },
    ],
  },
  {
    title: "Filter 4",
    items: [
      {
        label: "Condition 9",
        value: "condition9",
      },
      {
        label: "Condition 10",
        value: "condition10",
      },
      {
        label: "Condition 11",
        value: "condition11",
      },
      {
        label: "Condition 12",
        value: "condition12",
      },
    ],
  },
];

export const sortFilterAlphabeticalData = [
  {
    label: "Alphabetical (A-Z)",
    value: "a-z",
  },
  {
    label: "Alphabetical (Z-A)",
    value: "z-a",
    selected: true,
  },
];

export const collectionsFilterData = [
  {
    label: "Blues",
    value: "blues",
  },
  {
    label: "Browns",
    value: "browns",
  },
  {
    label: "Greens",
    value: "greens",
  },
  {
    label: "Whites and Neutrals",
    value: "whites-and-neutrals",
  },
];

export const productTypeFilterData = [
  {
    label: "Sample Pot",
    value: "sample-pot",
  },
  {
    label: "Swatch",
    value: "swatch",
  },
];
