import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import routes from "../../lib/routes";
import Modal from "react-modal";
import Button from "./Button";

const collectionLinks = [
  {
    link: routes.COLLECTION("whites"),
    label: "Whites",
  },
  {
    link: routes.COLLECTION("neutrals"),
    label: "Neutrals",
  },
  {
    link: routes.COLLECTION("greys"),
    label: "Greys",
  },
  {
    link: routes.COLLECTION("browns"),
    label: "Browns",
  },
  {
    link: routes.COLLECTION("pinks-and-purples"),
    label: "Pinks and Purples",
  },
  {
    link: routes.COLLECTION("blues"),
    label: "Blues",
  },
  {
    link: routes.COLLECTION("greens"),
    label: "Greens",
  },
  {
    link: routes.COLLECTION("yellows-and-oranges"),
    label: "Yellows and Oranges",
  },
  {
    link: routes.COLLECTION("reds"),
    label: "Reds",
  },
  {
    link: routes.COLLECTION("colorbond"),
    label: "Colorbond®",
  },
  {
    link: routes.COLLECTION("colour-stickers"),
    label: "Colour Stickers",
  },
  {
    link: routes.COLLECTION("colour-forecast-2022"),
    label: "Colour Forecast 2022",
  },
  {
    link: routes.COLLECTION("specifier-tools"),
    label: "Specifier Tools",
  },
];

const bundleLinks = [
  {
    link: routes.PRODUCT("cool-whites"),
    label: "Cool Whites",
  },
  {
    link: routes.PRODUCT("warm-whites"),
    label: "Warm Whites",
  },
  {
    link: routes.PRODUCT("most-loved-whites"),
    label: "Most Loved Whites",
  },
  {
    link: routes.PRODUCT("dark-grey"),
    label: "Dark Greys",
  },
  {
    link: routes.PRODUCT("light-grey"),
    label: "Light Greys",
  },
  {
    link: routes.PRODUCT("exterior-lights"),
    label: "Exterior Lights",
  },
  {
    link: routes.PRODUCT("exterior-darks"),
    label: "Exterior Darks",
  },
];

const MobileMenu = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <div
      className={
        "border-t-4 overflow-y-scroll fixed md:hidden top-0 mt-32 bottom-0 left-0 z-40 w-full mobile-menu " +
        (props.mobileMenuActive ? "mobile-menu--active" : "")
      }
      style={{
        backgroundColor: props.backgroundColour,
        borderColor: props.borderColour,
        color: props.textColour,
      }}
    >
      <ul className="mt-2">
        <div className="container mx-auto flex justify-between mx-2 flex-col">
          <li
            className="border-b px-4 pt-10 pb-2 uppercase"
            style={{ borderColor: props.borderColour }}
          >
            <Link href="/">
              <a>Home</a>
            </Link>
          </li>
        </div>
        <li>
          <div>
            <div className="container mx-auto flex flex-wrap justify-between mx-2">
              <ul className="w-full">
                <div className="flex items-center px-4 pt-8">
                  <span className="pt-2 tracking-widest uppercase">Colour</span>
                </div>
                <div className="text-lg text-light flex flex-col px-4 pt-4">
                  {collectionLinks.map(collection => (
                    <span key={collection.label}>
                      <Link {...collection.link}>
                        <a className="text-sm py-3 block">{collection.label}</a>
                      </Link>
                    </span>
                  ))}
                </div>
              </ul>
              <ul className="w-full">
                <div className="flex items-center px-4 pt-8">
                  <span className="pt-2 tracking-widest uppercase">
                    Bundles
                  </span>
                </div>
                <div className="text-lg text-light flex flex-col px-4 pt-4">
                  {bundleLinks.map(bundle => (
                    <span key={bundle.label}>
                      <Link {...bundle.link}>
                        <a className="text-sm py-3 block">{bundle.label}</a>
                      </Link>
                    </span>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </li>
        <div className="container mx-auto flex justify-between mx-2 flex-col">
          <li
            className="border-b px-4 pt-10 pb-2 uppercase"
            style={{ borderColor: props.borderColour }}
          >
            <Link href="/shop">
              <a>Shop All</a>
            </Link>
          </li>
        </div>
        <div className="container mx-auto flex justify-between mx-2 flex-col">
          <li className="border-b border-gray-300 px-4 pt-10 pb-2 uppercase">
            <a className="cursor-pointer" onClick={openModal}>
              Dulux Website
            </a>
          </li>
        </div>
      </ul>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
          document.body.classList.add("overflow-hidden");
        }}
        onRequestClose={() => {
          document.body.classList.remove("overflow-hidden");
          closeModal();
        }}
        contentLabel="Example Modal"
      >
        <p>Just a reminder that you're now leaving the Dulux Colour Shop.</p>
        <p className="my-4">
          Couldn't find what you’re looking for? Shop our full range of colour
          samples on the{" "}
          <a
            href="https://www.dulux.com.au/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Dulux website
          </a>
          .
        </p>
        <Button url="https://www.dulux.com.au/" target="_blank">
          Proceed
        </Button>
        <i
          className="fas fa-times text-xl absolute cursor-pointer"
          onClick={() => {
            document.body.classList.remove("overflow-hidden");
            closeModal();
          }}
          style={{ top: "15px", right: "15px" }}
        ></i>
      </Modal>
    </div>
  );
};

MobileMenu.propTypes = {
  pages: PropTypes.array,
  mobileMenuActive: PropTypes.bool,
  textColour: PropTypes.string,
  backgroundColour: PropTypes.string,
  borderColour: PropTypes.string,
};

MobileMenu.defaultProps = {
  pages: [],
  mobileMenuActive: false,
  textColour: "#FFFFFF",
  backgroundColour: "#001E45",
  borderColour: "#001E45",
};

export default MobileMenu;
