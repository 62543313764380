import TagManager from 'react-gtm-module';
import get from "lodash/get";

function pushDataLayer(dataLayer) {
  return TagManager.dataLayer({ dataLayer });
}

export function trackEvent(event, eventCategory, eventAction, eventLabel) {
  return pushDataLayer({
    event,
    eventCategory,
    eventAction,
    eventLabel,
  });
}

function getProductTrackingData(product, variant, quantity, index, extra = {}) {
  variant = variant ? variant : get(product, 'variants.edges.0.node');

  const data = {
    name: get(product, 'title'),
    id: get(variant, 'sku'),
    price: get(variant, 'priceV2.amount', get(product, "priceRange.maxVariantPrice.amount")),
    category: get(product, 'productType'),
    variant: get(variant, 'title'),
  };

  if (typeof quantity === 'number' && quantity > 0) {
    data.quantity = quantity;
  }

  if (typeof index === 'number') {
    data.position = index + 1;
  }

  return {
    ...data,
    ...extra,
  }
}

// Measures product impressions.
// This could be fired when the user views a list of products.
//
// products: the array of Shopify Products
export function productImpression(products = []) {
  return pushDataLayer({
    ecommerce: {
      currencyCode: 'AUD',
      impressions: products.map((product, i) => getProductTrackingData(product, undefined, undefined, i)),
    },
  });
}

// Call this function when a user clicks on a product link.
// The callback function is called after the event has been sent to Google,
// and *could* be use for navigation.
//
// product: the Shopify product
// variant: the Shopify variant (defaults to the first variant if set to `undefined`)
// list: the list the user used: ie: Search Results, Shop, etc
export function productClick(product, variant, list = 'Shop', callback) {
  const data = {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {list},
        products: [
          getProductTrackingData(product, variant),
        ],
      },
    },
  };

  if (callback) {
    data.eventCallback = callback;
  }

  return pushDataLayer(data);
}

// Measure a view of product details.
//
// product: the Shopify product
// variant: the Shopify variant (defaults to the first variant if set to `undefined`)
// list: the list the user used: ie: Search Results, Shop, etc
export function productView(product, variant, list = 'Shop') {
  return pushDataLayer({
    ecommerce: {
      detail: {
        actionField: {list},
        products: [
          getProductTrackingData(product, variant),
        ],
      },
    },
  });
}

// Measure adding a product to a shopping cart.
//
// product: the Shopify product
// variant: the Shopify variant (defaults to the first variant if set to `undefined`)
export function addToCart(product, variant, quantity = 1) {
  return pushDataLayer({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'AUD',
      add: {
        products: [
          getProductTrackingData(product, variant, quantity),
        ]
      }
    }
  });
}

// Measure the removal of a product from a shopping cart.
//
// product: the Shopify product
// variant: the Shopify variant (defaults to the first variant if set to `undefined`)
export function removeFromCart(product, variant, quantity = 1) {
  return pushDataLayer({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          getProductTrackingData(product, variant, quantity),
        ]
      }
    }
  });
}
