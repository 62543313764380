import React from "react";
import PropTypes from "prop-types";

const Hamburger = props => {
  function activeClass() {
    return props.mobileMenuActive ? "active" : "";
  }

  return (
    <>
      <div
        className={"menu-trigger " + activeClass()}
        onClick={() => props.toggleActiveState()}
      >
        <span style={{ backgroundColor: props.colour }}></span>
        <span style={{ backgroundColor: props.colour }}></span>
        <span style={{ backgroundColor: props.colour }}></span>
      </div>
    </>
  );
};

Hamburger.propTypes = {
  mobileMenuActive: PropTypes.bool,
  toggleActiveState: PropTypes.func,
  colour: PropTypes.string,
};

Hamburger.defaultProps = {
  mobileMenuActive: false,
  toggleActiveState: null,
  colour: "#001E45",
};

export default Hamburger;
