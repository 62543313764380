import duluxLogo from "../public/dulux-logo.png";

export const navData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Shop",
    link: "/shop",
  },
  {
    title: "Cart",
    link: "/cart",
  },
];

export const footerData = {
  description: "2019 Dulux",
  logo: {
    src: duluxLogo,
    alt: "Dulux Logo",
  },
  links: {
    facebook: "https://www.facebook.com/DuluxAustralia/",
    instagram: "https://www.instagram.com/duluxaus/",
    pinterest: "https://www.pinterest.com.au/duluxaustralia/",
    youtube: "https://www.youtube.com/user/duluxaustralia",
  },
};
