// npm docs on how to use
// https://www.npmjs.com/package/mixpanel-browser

import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.MIXPANEL_API_KEY);

let mixpanelActions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
    // console.log(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export default mixpanelActions;
