import React, { useState, useEffect } from "react";
import { footerData } from "../../data/globals";
import { Facebook, Instagram, Youtube } from "react-feather";
import Newsletter from "../ui/Newsletter";
import LiveChat from "react-livechat";
import Link from "next/link";
import routes from "../../lib/routes";

import amex from "../../public/icons/amex_icon.svg";
import apple from "../../public/icons/apple_pay_icon.svg";
import gpay from "../../public/icons/gpay_icon.svg";
import master from "../../public/icons/master_icon.svg";
import pay from "../../public/icons/pay_icon.svg";
import visa from "../../public/icons/visa_icon.svg";

const Footer = () => {
  const [initChat, setInitChat] = useState(false);
  useEffect(() => {
    setInitChat(true);
  }, []);
  return (
    <>
      <div>
        <Newsletter />
        <div className=" text-sm bg-dulux-dark text-white">
          <div className="container px-3 mx-auto py-10 flex flex-col sm:flex-row justify-between items-left sm:items-start">
            <div className="text-left sm:text-left w-full md:w-3/5">
              <Link href="/">
                <a className="inline-flex">
                  <img
                    src={footerData.logo.src}
                    alt={footerData.logo.alt}
                    className="h-8"
                  />
                </a>
              </Link>
              <div className="flex flex-col md:flex-row pt-6 md:pt-10 text-left">
                <div className="w-full md:w-3/5">
                  <div className="pb-6 pr-0 md:pr-8 flex flex-col">
                    <span>Dulux Colour Shop</span>
                    <div className="pt-6 flex">
                      <img src={amex} alt={"amex"} className="w-8 mr-2" />
                      <img src={apple} alt={"apple"} className="w-8 mr-2" />
                      <img src={gpay} alt={"gpay"} className="w-8 mr-2" />
                      <img src={master} alt={"master"} className="w-8 mr-2" />
                      <img src={pay} alt={"pay"} className="w-8 mr-2" />
                      <img src={visa} alt={"visa"} className="w-8" />
                    </div>
                    <span className="pt-6">
                      Can’t find what you’re looking for? Our Colour Shop
                      contains a curated range of colours based on popularity.
                      Shop our full range of colour samples on the
                      <a
                        href="https://www.dulux.com.au"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline pl-1"
                      >
                        Dulux website.
                      </a>
                    </span>
                    <span className="pt-6">Need help? Call us on 13 25 25</span>
                  </div>
                </div>
                <div className="w-2/5 pt-8 md:pt-0">
                  <ul>
                    <li className="pb-3">
                      <Link {...routes.PAGE("/support-and-faqs")}>
                        <a className="pl-0 md:pl-2">Shipping Support &#38; FAQs</a>
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link {...routes.PAGE("/returns")}>
                        <a className="pl-0 md:pl-2">Returns</a>
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link {...routes.PAGE("/site-terms")}>
                        <a className="pl-0 md:pl-2">Site Terms</a>
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link {...routes.PAGE("/privacy-policy")}>
                        <a className="pl-0 md:pl-2">Privacy Policy</a>
                      </Link>
                    </li>
                    <li>
                      <Link {...routes.PAGE("/privacy-collection-statement")}>
                        <a className="pl-0 md:pl-2">
                          Privacy Collection Statement
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <ul className="flex items-center mt-10 pb-10 sm:mt-0 sm:pb-0">
                <li className="mr-6">
                  <a
                    href={footerData.links.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook
                      className="inline-block"
                      width="24"
                      height="24"
                      strokeWidth="2"
                      stroke="#FFFFFF"
                    />
                  </a>
                </li>
                <li className="mr-6">
                  <a
                    href={footerData.links.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram
                      className="inline-block"
                      width="24"
                      height="24"
                      strokeWidth="2"
                      stroke="#FFFFFF"
                    />
                  </a>
                </li>
                <li className="mr-6">
                  <a
                    href={footerData.links.pinterest}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/icons/pinterest.svg" />
                  </a>
                </li>
                <li>
                  <a
                    href={footerData.links.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Youtube
                      className="inline-block"
                      width="24"
                      height="24"
                      strokeWidth="2"
                      stroke="#FFFFFF"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {initChat === true && <LiveChat license={5754701} group={19} />}
    </>
  );
};

export default Footer;
